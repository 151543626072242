import Navbar from '../Components/Navbar';

const Home = () => {
	return (
	<>
	<Navbar />
	</>
	)
}

export default Home