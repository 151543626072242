import { Link } from "react-router-dom";
import { AccountCircleRounded, CloseRounded, HomeRounded, DescriptionRounded } from "@material-ui/icons";

import { useState, useEffect } from "react";

import { deviceType, isDeveloping } from "../LibUseful";

import "./Stylesheets/Navbar.css"

const Navbar = () => {
	const [isOpen, toggleState] = useState(false);
	const [navStatus, setNavStatus] = useState(0); //0 no class, 1 fix, 2 scroll, 3 mobile, 4 mobile on scroll, 5 mobile w/ search	

	const toggleUserPopup = () => {
		switch(isOpen){
			case true:
				toggleState(false);
				if(window.scrollY == 0){setNavStatus(0)} else {setNavStatus(2)}
				break;
			case false:
				toggleState(true);
				if(window.scrollY == 0){setNavStatus(1)} else {setNavStatus(1)}
				break;
			default:
				toggleState(false);
				break;
		}
	}

	useEffect(() => {
	  	window.addEventListener("scroll", (e) => navStatusHandler(e));
	  	return  () => {
			window.removeEventListener("scroll", (e) => navStatusHandler(e));
		};
	}, []);

	const navStatusHandler = (event) => {
		if(isOpen === true){
			if(window.scrollY === 0){
				setNavStatus(1);
			}else{
				setNavStatus(1);
			}
		} else {
			if(window.scrollY === 0){
				setNavStatus(0);
			}else{
				setNavStatus(2);
			}
		}
	}

	return (
		<>

			{deviceType.isMobile ? 
			<><span className="navTopMarginMobile"></span>
			<nav className="navMobile">
				<span className="navIcon">
					<HomeRounded />
					<small>Home</small>
				</span>

				<span className="navIcon">

					<small></small>
				</span>

				<span className="navIcon">
					
					<small></small>
				</span>

				<span className="navIcon">
					<DescriptionRounded />
					<small></small>
				</span>

				<span className="navIcon">
					<AccountCircleRounded />
					<small></small>
				</span>
			</nav>
			<nav className={navStatus === 5 ? "navMobileTxInput" : "hidden"}>

			</nav>
			</>
			:
			<><nav className={navStatus == 0 ? "" : navStatus == 1 ? "navFixed" : "navOnScroll"}>
				<span className="navTitle">
					{/* Add icon */}
					<h2 className="nunito">Atomtech</h2>
				</span>
				<span className="navLinks">
					<Link to="/">Home</Link>
					{/* <span onClick={toggleUserPopup}>
						<AccountCircleRounded />
					</span> */}
				</span>
			</nav>
			<section className={isOpen ? "navbarPopups" : "hidden navbarPopupsAni"}>
				<div className="userPopup">
					{
						isDeveloping && <div className="navDevMenu">
							<h3>Development Menu</h3>
							
						</div>
					}
				</div>
			</section>
			<span className={navStatus != 0 ? "navPlaceholder" : ""}></span></>
			}

			
		</>
	)
}

export default Navbar


